<template>
  <v-row>
    <v-col cols="12">لیست پیام‌ها</v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Message'
}
</script>
