<template>
  <ValidationObserver ref="observer">
    <form>
      <!-- User Name Field -->
      <ValidationProvider
        v-slot="{ errors }"
        name="نام کاربری"
        rules="required"
      >
        <v-text-field
          v-model="userName"
          :error-messages="errors"
          label="نام کاربری"
          outlined
          prepend-inner-icon="mdi-account"
        ></v-text-field>
      </ValidationProvider>

      <!-- Password Field -->
      <ValidationProvider v-slot="{ errors }" name="رمز عبور" rules="required">
        <v-text-field
          type="password"
          v-model="password"
          :error-messages="errors"
          label="رمز عبور"
          outlined
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
      </ValidationProvider>

      <!-- Form Button -->
      <v-btn block x-large color="primary" :loading="isLoading" @click="login">
        <span>ورود</span>
      </v-btn>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'LoginForm',

  // Props
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  // Data
  data () {
    return {
      userName: '',
      password: ''
    }
  },

  // Methods
  methods: {
    login () {
      this.$refs.observer.validate().then((response) => {
        if (response) {
          this.$emit('login-submit', {
            userName: this.userName,
            password: this.password
          })
        } else {
          this.$toast.error('لطفا مقادیر را با دقت تکمیل نمایید')
        }
      })
    }
  }
}
</script>
