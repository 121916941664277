<template>
  <v-container fluid fill-height>
    <!-- Main Content -->
    <v-main>
      <!-- Login Image -->
      <v-row align="center" justify="center">
        <v-img
          lazy-src="/static/img/login-banner.webp"
          src="/static/img/login-banner.webp"
          width="230"
          height="230"
          contain
        ></v-img>
      </v-row>

      <!-- Login Form -->
      <v-row align="center" justify="center" class="pt-4 pb-15">
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" class="text-center mb-3">
          <h4 class="text-right pb-3">ورود به حساب کاربری</h4>
          <LoginForm :isLoading="isLoading" @login-submit="login"></LoginForm>
        </v-col>
      </v-row>
    </v-main>

    <!-- Copy Right -->
    <v-bottom-navigation
      absolute
      class="copy-right pa-4 text-caption elevation-2"
      height="auto"
    >
      <span>
        تمامی حقوق این نرم‌افزار برای
        <a href="https://nikansoft.com" target="_blank"
          >گروه نرم افزاری نیکان</a
        >
        محفوظ می‌باشد.
      </span>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import LoginForm from '../components/account/LoginForm'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Home',

  // Components
  components: {
    LoginForm
  },

  // Data
  data () {
    return {
      isLoading: false
    }
  },

  // Computed
  computed: {
    ...mapGetters('account', ['isAuthenticatedGetter']),
    ...mapGetters('account', ['idGetter']),
    ...mapGetters('report', ['cyclesGetter'])
  },

  // Created
  created () {
    if (this.isAuthenticatedGetter) {
      this.$router.push({ name: 'Dashboard' })
    }
  },

  // Methods
  methods: {
    ...mapActions({ loginAction: 'account/login' }),
    ...mapActions({ getReportAction: 'report/getReport' }),

    btnLoadFalse (duration) {
      setTimeout(() => {
        this.isLoading = false
      }, duration)
    },

    getCycles () {
      axios.get(`api/Report/cycles/${this.idGetter}`, {})
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('report/SET_CYCLES', {
              cycles: response.data.data
            })
            this.getReport()
          } else {
            this.$toast.error(response.data.message)
          }
        })
        .catch((error) => {
          this.$toast.error(error.message)
        })
    },

    getReport () {
      const data = {
        fromDate: '',
        toDate: '',
        cycleCode: this.cyclesGetter[0].cycl_Code
      }

      this.getReportAction(data)
        .then((response) => {
          if (!response.data.success) {
            this.$toast.error(response.data.message)
          } else {
            this.$router.push({ name: 'Dashboard' })
          }
        })
        .catch((error) => {
          this.$toast.error(error.message)
        })
    },

    login (data) {
      this.isLoading = true

      this.loginAction(data)
        .then((response) => {
          if (!response.data.success) {
            this.$toast.error(response.data.message)
            this.btnLoadFalse(500)
          } else {
            this.getCycles()
          }
        })
        .catch((error) => {
          this.btnLoadFalse(500)
          this.$toast.error(error.message)
        })
    }
  }
}
</script>

<style>
.copy-right a {
  text-decoration: none;
}
</style>
