<template>
  <v-app-bar elevation="1" rounded="lg">
    <v-app-bar-nav-icon @click="clickDrawerButton">
      <v-icon v-if="!drawer">mdi-menu-open</v-icon>
      <v-icon class="close-drawer-icon" v-else>mdi-menu-open</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="text-subtitle-2 pr-0">
      <span>داشبورد نیکان</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- Account Menu -->
    <v-menu offset-y right transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-card-title
          class="pr-2 pl-0 text-subtitle-2"
          v-bind="attrs"
          v-on="on"
        >
          <span>حساب کاربری</span>
        </v-card-title>
        <v-sheet class="pa-1" rounded="circle" v-bind="attrs" v-on="on">
          <v-avatar size="42">
            <v-img
              src="/static/img/account-img.png"
              lazy-src="/static/img/account-img.png"
            ></v-img>
          </v-avatar>
        </v-sheet>
      </template>
      <v-list dense nav>
        <v-list-item
          link
          v-for="(item, index) in AccountMenuItems"
          :key="index"
        >
          <v-list-item-title class="text-subtitle-2" @click="item.method">
            <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
            <span class="mr-2">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderAppBar',

  // Props
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },

  // Data
  data () {
    return {
      AccountMenuItems: [
        {
          title: 'پیام ها',
          icon: 'mdi-message-text',
          iconColor: 'green',
          method: this.messageRout
        },
        {
          title: 'خروج از حساب کاربری',
          icon: 'mdi-logout-variant',
          iconColor: 'red',
          method: this.logout
        }
      ]
    }
  },

  // Methods
  methods: {
    clickDrawerButton () {
      this.$emit('clickDrawerButton')
    },

    messageRout () {
      const path = '/message'
      if (this.$route.path !== path) {
        this.$router.push({ name: 'Message' })
      }
    },

    logout () {
      this.$emit('logout')
    }
  }
}
</script>

<style>
.close-drawer-icon {
  transform: rotate(180deg);
}
</style>
