<template>
  <v-app>
    <v-dialog v-model="dialog" width="400" persistent>
      <v-card>
        <v-card-title class="text-subtitle-1 grey lighten-2">
          <v-icon color="green">mdi-checkbox-marked-outline</v-icon>
          <span class="pr-1">افزودن اپلیکیشن به صفحه اصلی</span>
        </v-card-title>

        <v-card-text class="pt-5">
          اگر مایل به افزودن این اپلیکیشن روی صفحه اصلی هستید، لطفا روی دکمه
          قبول می‌کنم کلیک کنید.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" tile text @click="dialog = false">
            مایل نیستم
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" tile elevation="0" @click="installer()">
            قبول می‌کنم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  // ِData
  data () {
    return {
      dialog: false,
      installer: undefined
    }
  },

  created () {
    let installPrompt

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()

      installPrompt = e

      this.dialog = true
    })

    this.installer = () => {
      this.dialog = false

      installPrompt.prompt()

      installPrompt.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          this.dialog = false
          console.log('user accepted')
        } else {
          this.dialog = false
          console.log('user denied')
        }
        installPrompt = null
      })
    }
  }
}
</script>
