import axios from 'axios'

export const state = {
  id: '',
  accessToken: '',
  isAuthenticated: false
}

export const mutations = {
  SET_USER_DATA (state, data) {
    state.id = data.id
    state.accessToken = data.accessToken
    state.isAuthenticated = data.isAuthenticated
  }
}

export const actions = {
  login ({ commit }, data) {
    const form = new FormData()
    form.append('userName', data.userName)
    form.append('password', data.password)
    form.append('appType', 'dashboard')

    const headers = { 'Content-Type': 'x-www-form-urlencoded' }

    return axios
      .post('api/token', form, { headers: headers })
      .then(response => {
        if (response.data.success) {
          const data = { isAuthenticated: true, ...response.data.data }
          commit('SET_USER_DATA', data)
          return response
        } else {
          return response
        }
      })
  },

  logout ({ commit }) {
    const data = {
      accessToken: '',
      isAuthenticated: false
    }
    commit('SET_USER_DATA', data)
  }
}

export const getters = {
  idGetter: state => state.id,
  isAuthenticatedGetter: state => !!state.isAuthenticated,
  accessTokenGetter: state => state.accessToken
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
