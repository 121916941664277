import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Vue Toastification
import vueToastification from './plugins/vueToastification'

// Axios
import axiosConfig from './plugins/axios'

// Vee Validate
import veeValidate from './plugins/veeValidate'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  veeValidate,
  vueToastification,
  axiosConfig,
  render: h => h(App)
}).$mount('#app')
