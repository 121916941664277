<template>
  <v-dialog class="white" :value="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="dialogOpener"
        @click="dialogHandle"
        v-bind="attrs"
        v-on="on"
      ></slot>
    </template>
    <v-card>
      <v-btn
        class="mb-3"
        fab
        elevation="0"
        small
        icon
        tile
        @click="dialogHandle"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text>
        <v-container class="pa-0">
          <ValidationObserver ref="observer">
            <form>
              <v-row align="center" justify="center">
                <v-col cols="12" class="pa-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="انتخاب دوره مالی"
                    rules="required"
                  >
                    <v-select
                      v-model="cycleName"
                      :items="cycleNames"
                      label="انتخاب دوره مالی"
                      :error-messages="errors"
                      persistent-hint
                      return-object
                      single-line
                      outlined
                      @change="getCycleCode(cycleName)"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="12" class="pa-0">
                  <VuePersianDatetimePicker
                    v-model="startDate"
                    label="شروع"
                  ></VuePersianDatetimePicker>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="12" class="pa-0">
                  <VuePersianDatetimePicker
                    v-model="endDate"
                    label="پایان"
                    :disabled="!startDate"
                    :min="startDate"
                  ></VuePersianDatetimePicker>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="12" class="pa-0">
                  <v-btn
                    elevation="0"
                    large
                    color="primary"
                    block
                    @click="getReport"
                    :loading="isLoading"
                  >
                    گرفتن اطلاعات
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import { mapGetters } from 'vuex'

export default {
  name: 'GetReportForm',

  // Components
  components: {
    VuePersianDatetimePicker
  },

  // Props
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  // Data
  data () {
    return {
      startDate: '',
      endDate: '',
      cycleName: '',
      cycleCode: '',
      cycleNames: []
    }
  },

  // Computed
  computed: {
    ...mapGetters('report', ['cyclesGetter'])
  },

  // Mounted
  mounted () {
    for (let index = 0; index < this.cyclesGetter.length; index++) {
      const element = this.cyclesGetter[index]
      this.cycleNames.push(element.cycl_Name)
    }
  },

  // Methods
  methods: {
    dialogHandle () {
      this.$emit('dialogHandle')
    },

    getReport () {
      this.$refs.observer.validate().then((response) => {
        if (response && this.startDate !== '' && this.endDate !== '') {
          this.$emit('getReport', {
            fromDate: this.startDate,
            toDate: this.endDate,
            cycleCode: this.cycleCode
          })
        } else {
          this.$toast.error('لطفا مقادیر را با دقت تکمیل نمایید')
        }
      })
    },

    getCycleCode (cycleName) {
      for (let index = 0; index < this.cyclesGetter.length; index++) {
        const element = this.cyclesGetter[index]
        if (element.cycl_Name === cycleName) {
          this.cycleCode = element.cycl_Code
          break
        }
      }
    }
  }
}
</script>
