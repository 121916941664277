<template>
  <v-navigation-drawer
    width="300"
    :value="drawer"
    app
    right
    @input="drawerChangedFire"
  >
    <v-list dense nav class="list">
      <!-- Logo -->
      <a href="https://nikansoft.com" target="_blank">
        <v-list-item>
        <v-list-item-avatar size="80">
          <v-img
            src="/static/img/logo.webp"
            lazy-src="/static/img/logo.webp"
            contain
            width="80"
            height="80"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="text-subtitle-2">
          <span>گروه نرم افزاری نیکان</span>
        </v-list-item-content>
      </v-list-item>
      </a>
      <v-divider class="mb-2"></v-divider>
      <!-- Get Report Form -->
      <v-list-item>
        <GetReportForm
          @dialogHandle="dialogHandle"
          :dialog="dialog"
          :isLoading="isLoading"
          @getReport="getReport"
        >
          <v-btn
            elevation="0"
            block
            large
            color="primary"
            slot="dialogOpener"
            @click="dialogHandle"
          >
            <v-icon>mdi-calendar-range</v-icon>
            <span class="mr-2">انتخاب بازه زمانی</span>
          </v-btn>
        </GetReportForm>
      </v-list-item>

      <v-divider class="mt-2 mb-6"></v-divider>

      <!-- Static Nav Items -->
      <v-list-item
        v-for="item in navItems"
        :key="item.title"
        link
        to="/dashboard"
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Server Nav Items -->
      <v-list-group
        :value="false"
        prepend-icon="mdi-arrow-decision"
        v-for="item in this.reportGetter"
        :key="item.id"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </template>
        <v-list-item
          link
          v-for="subItem in item.reportTypes"
          :key="subItem.id"
          class="mr-2"
          :to="'/report/' + subItem.id"
        >
          <v-list-item-icon class="ml-1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ subItem.name }}</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <!-- Copy Right -->
      <v-list-item
        class="copy-right nikan-privacy pa-4 text-caption elevation-2"
      >
        <span>
          تمامی حقوق این نرم‌افزار برای
          <a href="https://nikansoft.com" target="_blank"
            >گروه نرم افزاری نیکان</a
          >
          محفوظ می‌باشد.
        </span>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GetReportForm from '../../report/GetReportForm'

export default {
  name: 'NavigationDrawer',

  // Props
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },

  // Data
  data () {
    return {
      dialog: false,
      isLoading: false,
      navItems: [{ title: 'داشبورد', icon: 'mdi-view-dashboard' }]
    }
  },

  // Computed
  computed: {
    ...mapGetters('report', ['cyclesGetter', 'reportGetter'])
  },

  // Components
  components: {
    GetReportForm
  },

  // Methods
  methods: {
    ...mapActions({ getReportAction: 'report/getReport' }),

    drawerChangedFire (val) {
      this.$emit('drawerChangedFire', val)
    },

    dialogHandle () {
      this.dialog = !this.dialog
    },

    btnLoadFalse (duration) {
      setTimeout(() => {
        this.isLoading = false
      }, duration)
    },

    getReport (data) {
      this.isLoading = true

      this.getReportAction(data)
        .then((response) => {
          if (!response.data.success) {
            this.$toast.error(response.data.message)
            this.btnLoadFalse(500)
          } else {
            this.isLoading = false
            this.dialog = false
            window.location.reload()
          }
        })
        .catch((error) => {
          this.$toast.error(error.message)
          this.btnLoadFalse(500)
        })
    }
  }
}
</script>

<style>
.nikan-privacy {
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: unset !important;
}

.theme--light .nikan-privacy {
  background-color: #ffffff;
}

.list {
  padding-bottom: 80px;
}

a {
  text-decoration: none;
}
</style>
