<template>
  <v-app>
    <!-- Navigation Drawer -->
    <NavigationDrawer
      :drawer="drawer"
      @drawerChangedFire="drawerChangedFire"
    ></NavigationDrawer>

    <!-- Main Content -->
    <v-main>
      <v-container fluid>
        <HeaderAppBar
          :drawer="drawer"
          @logout="logout"
          @clickDrawerButton="clickDrawerButton"
        ></HeaderAppBar>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import HeaderAppBar from '../../components/layouts/primary/HeaderAppBar'
import NavigationDrawer from '../../components/layouts/primary/NavigationDrawer'

export default {
  name: 'PrimaryLayout',

  // Components
  components: {
    NavigationDrawer,
    HeaderAppBar
  },

  // Data
  data () {
    return {
      drawer: false
    }
  },

  // Methods
  methods: {
    ...mapActions({ logoutAction: 'account/logout' }),

    clickDrawerButton () {
      this.drawer = !this.drawer
    },

    drawerChangedFire (val) {
      this.drawer = val
    },

    logout () {
      this.logoutAction()
      if (this.logoutAction()) {
        window.localStorage.removeItem('vuex')
        this.$router.push({ name: 'Home' })
        this.$toast.success('خروج شما با موفقیت انجام شد.')
      } else {
        this.$toast.warning('مشکلی رخ داده است. لطفا مجدد تلاش نمایید')
      }
    }
  }
}
</script>
