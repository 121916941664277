<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <span class="pb-4">{{ report.name }}</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="جستجو"
            filled
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headerTable"
          :items="report.content"
          :items-per-page="5"
          :search="search"
          mobile-breakpoint="0"
        ></v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Report',

  // Computed
  computed: {
    ...mapGetters('report', ['reportGetter'])
  },

  // Data
  data () {
    return {
      report: [],
      headerTable: [],
      reportId: null,
      search: ''
    }
  },

  // Before Route Update
  beforeRouteUpdate (to, from, next) {
    this.reportId = to.params.id
    this.dataCustomizer()
    next()
  },

  // Created
  created () {
    this.reportId = this.reportId || this.$route.params.id
    this.dataCustomizer()
  },

  // Methods
  methods: {
    dataCustomizer () {
      for (let i = 0; i < this.reportGetter.length; i++) {
        const reports = this.reportGetter[i]
        for (let j = 0; j < reports.reportTypes.length; j++) {
          const report = reports.reportTypes[j]
          if (report.id === Number(this.reportId)) {
            this.report = report
            this.headerTable = []
            this.headerTable.push(
              {
                text: this.report.reportDescCaption,
                value: 'reportDesc'
              },
              {
                text: this.report.reportCountCaption,
                value: 'reportCount'
              },
              {
                text: this.report.reportValueCaption,
                value: 'reportValue'
              }
            )
            break
          }
        }
      }
    }
  }
}
</script>
