<template>
  <v-row>
    <v-col cols="12">داشبورد</v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',

  // Computed
  computed: {
    ...mapGetters('account', ['isAuthenticatedGetter'])
  },

  // Created
  created () {
    if (!this.isAuthenticatedGetter) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
