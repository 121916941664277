import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/fa'

const config = Vue.use({
  validationProvider: Vue.component('ValidationProvider', ValidationProvider),
  validationObserver: Vue.component('ValidationObserver', ValidationObserver),

  rules: Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    })
  })
})

export default config
