import axios from 'axios'
import store from '../store'
window.axios = axios

const axiosConfig = axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.VUE_APP_BASE_URL
    try {
      const token = store.getters['account/accessTokenGetter']
      config.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      return config
    } catch (error) {
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default axiosConfig
